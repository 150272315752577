/* @media (max-width: 1023px) {
}
@media (max-width: 767px) {
}
@media (max-width: 480px) {
} */

/* font */
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
.notosanskr * { 
 font-family: 'Noto Sans KR', sans-serif;
}

/* nomal */
body, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'Noto Sans KR', sans-serif;
}

body * {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

/* library */
.bg-red {
  background-color: red;
}

.bg-gold {
  background-color: gold;
}

.bg-blue {
  background-color: blue;
}

.bg-gray {
  background: #f5f6f9;
;
}

/* custom */
:root {
  --header-width: 1400px;
  --header-height: 80px;
  --header-mobile-height: 50px;
  --detail-width: 1200px;
  --detail-right-width: 350px;
}

@media (max-width: 1023px) {
    :root {
        --header-height: 60px;
    }
}

:root {

  --page-width: 1200px;
  --page-padding-pc: 0 40px;
  --page-padding-tab: 0 30px;
  --page-padding-mo: 0 20px;
}

.block  {
  display: block !important;
}

.none {
  display: none !important;
}

.none-pc {
    display: none !important;
}

.flex {
  display: flex !important;
}

.inline {
  display: inline !important;
}

.visible {
  visibility: visible !important;
}

.overflow-visible {
  overflow: visible !important;
}

.max-h100vh {
  max-height: 100vh !important;
}

.padding-top-20px {
  padding-top: 20px !important;
}

.underline {
  text-decoration: underline !important;
}

.bold {
    font-weight: bold !important;
}

.br-pc {
    display: block;
}
.br-mo {
    display: none;
}

@media (max-width: 1023px) {
    .br-pc {
        display: none;
    }    
    .br-mo {
        display: block;
    }
    .none-pc {
        display: block !important;
    }
    .none-mo {
        display: none !important;
    }
}


/* ======================================================== */
/* Header  */

.header {
    position: absolute;
    top: 30px;
    height: var(--header-height);
    width: 100%;
    z-index: 500;
    background-color: #FEEAF5;
    box-shadow: 0 0 5px #ffb0a6;
  }
  
  .header * {
      color: #297BD5
      /* color: #A3A3A3; */
  }
  
  .header__con {
    height: var(--header-height);
    max-width: var(--header-width);
    width: 100%;
    margin: 0 auto;
    padding: var(--page-padding-pc);
    display: flex;
    justify-content: space-between;
    color: #111;
    z-index: 300 !important;
  }
  
  .header__logo-con {
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .header__logo-con > li {
    height: 100%; 
    font-size: 23px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 28px;
  
  }
  
  .header__logo-con > li > a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .header__logo {
      height: 100%;
      display: flex;
  }

  .header__logo > a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header__logo > a > span:nth-child(1) {
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    letter-spacing: -1px;
    color: #020101;
  }

  .header__logo > a > span:nth-child(2) {
    margin-top: 10px;
    font-size: 25px;
    line-height: 25px;
    font-weight: 900;
    letter-spacing: -0.5px;
    /* color: #000; */
  }  
  
  .header__category-con{
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .header__category-con > li{
    height: 100%;
    /* width: 90px; */
    position: relative;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .header__category-con > li > a{
    padding: 0 20px;
  }

  .header__category-con > li:not(:last-child) > a {
    border-right: 2px solid #A3A3A3;
  }

  .header__hamburger {
    display: none;
  }
  
  .header__contact-btn {
      display: inline-block;
      /* background-color: #0173b1; */
      /* background-color: #111; */
  }
  
  .header__contact-btn > a {
    width: 130px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  
      font-size: 14px;
      background-image: linear-gradient(to right, #5DC492 0%, #297BDB 100%) !important;
      border-radius: 20px;
      color: #fff !important;
  }

  .header-mo {
      display: none;
  }
  
  /* .header-block {
      height: 85px;
  } */
    
  
@media (max-width: 1023px) {
    .header {
        top: 15px;
    }

    .header-unclick {
      height: 60px !important;
    }
  
    .header__con {
      height: 60px;
      display: block;
      padding: var(--page-padding-mo);
    }
  
    .header__logo-con {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  
    .header__logo > a {
      font-size: 20px;
    }

    .header__logo > a > span:nth-child(1) {
        font-size: 8px;
        line-height: 8px;
        font-weight: 500;
        /* letter-spacing: -1px;
        color: #353535; */
    }
    
    .header__logo > a > span:nth-child(2) {
        margin-top: 7px;
        font-size: 20px;
        line-height: 20px;
        font-weight: 800;
        /* letter-spacing: -0.5px; */
        /* color: #000; */
    }  
  
    .header__hamburger {
      display: block;
    }
  
    .header__hamburger img {
      width: 24px;
    }
  
  
    .header__category-con {
        display: none;
    }
  
    .header-mo {
        display: block;
        position: fixed;
        width: 80%;
        height: 100vh;
        left: -110%;
        top: 0;
        z-index: 999;
        transition: left .4s ease-in;
        background-color: #fff;
    }
  
    .left-0 {
        left: 0;
    }
  
    .header-mo__category-con {
        margin-top: 50px;
    }
  
    .header-mo__category-con > li > a {
        width: 100%;
        border-bottom: 1px solid #d5d5d5;
    }
  
    .header-mo__category-con > li:last-child > a {
        border: none;
    }

    .header-mo__category-con > li > a {
        display: inline-block;
        padding: 18px 30px;
        font-size: 14px;
        font-weight: 400;
    }

    .bg-gray {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 888;
        background-color: rgba(0, 0, 0, 0.8);
    }
}
  


/* ======================================================== */

.frame {
    position: relative;
    min-height: 100vh;

    background-color: #BCE5EB;
  }
  
.frame > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 1023px) {
  .frame {
    min-height: 750px;
  }
}


/* ======================================================== */

.frame__con {
    position: absolute;
    padding: 50px 100px;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* transform: translateX(-50%); */
    max-width: var(--page-width);
    width: 100%;
    height: 600px;
    background-color: #fff;
    border: 2px solid #6f6f6f;
    box-shadow: 0px 0px 10px #dedede;
    border-radius: 20px;
  
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }

@media (max-width: 1023px) {
    .frame__con {
        padding: 25px 25px;
        top: 55%;
        max-width: 320px;
        height: 550px;
    }
}

/* ======================================================== */
.menubar {
    display: flex;

    position: absolute;
    left: 10px;
    top: -50px;
    height: 50px;
}

.menubar a {
    margin-right: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;

    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: -0.5px;
    text-align: center;

    border: 2px solid #000;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #fff;
}

.menubar-after {
    background-color: #000 !important;
    color: #1AEBDB;
}

@media (max-width: 1023px) {
    .menubar {
        /* left: 10px; */
        top: -35px;
        height: 35px;
    }
    
    .menubar a {
        width: 100px;
    
        font-size: 10px;
        line-height: 14px;
    }
}

/* ========================================================= */

.swiper-button-prev {
    left: 0 !important;
}

.swiper-button-next {
    right: 0 !important;
}

.swiper-button-prev,
.swiper-button-next {
    width: 40px !important;
    height: 40px !important;
    background-color: #8c6430;
    font-size: 10px !important;
    color: #fff !important;
    border-radius: 100px ;
    opacity: 0.2;
}

.swiper-button-prev::after,
.swiper-button-next::after {
    font-size: 20px !important;
    font-weight: 800;
}


.swiper-pagination-bullet-active {
    width: 30px !important;
    border-radius: 5px !important;
    background-color: #dd6d41 !important;
}

@media (max-width: 1023px) {
    .swiper-button-prev,
    .swiper-button-next {
        top: 30% !important;
        width: 30px !important;
        height: 30px !important;
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
        font-size: 15px !important;
    }
}


/* ========================================================= */

.intro__content-con {
    height: 100%;
}

.intro__content-desc-con {
    height: 50%;
    display: flex;
    justify-content: space-between;
}

.intro__content-desc-txt-con {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;  
}

.intro__content-desc-txt-con > li:nth-child(1) {
    font-size: 40px;
    line-height: 40px;
    font-weight: 500;
    letter-spacing: -0.5px;
    color: #1AEBDB;
}

.intro__content-desc-txt-con > li:nth-child(2) {
    margin-top: 30px;
    margin-left: 10px;
    font-size: 45px;
    line-height: 55px;
    font-weight: 500;
    letter-spacing: -0.5px;
}

.intro__content-desc-img-con {
    width: 50%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
}

.intro__content-desc-img-con > img {
    /* width: 100px; */
    margin: 0 auto;
    height: 150px;
}

.intro__content-desc-img-con > div:nth-of-type(1) {
    margin-top: 30px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 800;
    letter-spacing: 1px;
}

.intro__content-desc-img-con > div:nth-of-type(2) {
    margin-top: 15px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 300;
    letter-spacing: -0.5px;
    color: #5b5b5b;
}

.intro__content-product-title {
    margin-top: 20px;
    margin-left: 60px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: -0.5px;
}

.intro__content-product-img-con {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.intro__content-product-img-con li {
    width: 150px;
    height: 200px;
    display: flex;
    justify-content: center;
    margin-bottom: 35px; /* adjusting position of pagination*/
}

.intro__content-product-img-con li > img {
    width: 100%;
    height: 100%;
    box-shadow: 2px 2px 6px #afafaf;
    cursor: pointer;
}


@media (max-width: 1023px) {
    .intro__content-desc-con {
        height: auto;
        flex-direction: column;
        justify-content: flex-start;
    }
    
    .intro__content-desc-txt-con {
        width: 100%;
    }

    .intro__content-desc-txt-con > li:nth-child(1) {
        font-size: 20px;
        line-height: 20px;
        text-align: center;
    }
    
    .intro__content-desc-txt-con > li:nth-child(2) {
        margin-top: 10px;
        margin-left: 0px;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
    }
    
    .intro__content-desc-img-con {
        margin-top: 30px;
        width: 100%
    }
    
    .intro__content-desc-img-con > img {
        height: 120px;
        /* height: auto; */
    }
    
    .intro__content-desc-img-con > div:nth-of-type(1) {
        margin-top: 30px;
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0px;
        /* text-align: center; */
    }
    
    .intro__content-desc-img-con > div:nth-of-type(2) {
        margin-top: 5px;
        font-size: 14px;
        line-height: 18px;
        word-break: keep-all;
    }
    
    .intro__content-product-title {
        margin-top: 30px;
        margin-left: 0;
        font-size: 14px;
        line-height: 14px;
        /* text-align: center; */
    }
    
    .intro__content-product-img-con {
        margin-top: 15px;
    }
    
    .intro__content-product-img-con li {
        width: 80px;
        height: auto;
    }
    
    .intro__content-product-img-con li > img {
        width: 100%;
        height: 100%;
    }
}

/* ======================================================== */

.product__select-con {
  margin-right: 50px;
  display: flex;
  justify-content: flex-end;
}

.product__select-con select {
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  color: #000 !important;
  border: 1px solid #5b5b5b !important;
  border-radius: 5px;
  background-color: #fff !important;
}

.product__content-con {
    height: 100%;
    display: flex;  /* 이걸 써야 swpierslide에서 flex가 제대로 적용됨 */
}

.product__swiperslide {
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
}

.product__content-img-wrap {
    height: 400px;
}

.product__content-img-wrap img {
    /* width: 100%; */
    height: 100%;
    box-shadow: 2px 2px 6px #afafaf;
}

.product__content-txt-con {
    width: 50%;
}

.product__content-txt-top > li:nth-of-type(1) {
    font-size: 40px;
    line-height: 40px;
    font-weight: 500;
    letter-spacing: -0.5px;
    color: #1AEBDB;
}

.product__content-txt-top > li:nth-of-type(2) {
    margin-top: 30px;
    margin-left: 10px;
    font-size: 45px;
    line-height: 50px;
    font-weight: 500;
    letter-spacing: -0.5px;
}

.product__content-txt-mid > li:nth-of-type(1) {
    margin-top: 30px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 800;
    letter-spacing: 1px;
}

.product__content-txt-mid > li:nth-of-type(2) {
    margin-top: 10px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 300;
    letter-spacing: -0.5px;
    color: #5b5b5b;    
}

.product__content-txt-bot {
    /* margin-left: 30px; */
}

.product__content-txt-bot > li {
    margin-top: 30px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -0.5px;
    word-break: keep-all;
    color: #4a4a4a;
}

@media (max-width: 1023px) {
  .product__select-con {
    margin: 0;
  }
  
  .product__select-con select {
    padding: 3px 5px;
    font-size: 12px;
    font-weight: 500;
    /* border-radius: 5px; */
    /* background-color: #fff !important; */
  }

  .product__content-con {
    margin-top: 30px;
      /* height: 100%; */
      display: block;
      /* flex-direction: column; */
  }
  
  .product__swiperslide {
      /* display: flex !important; */
      flex-direction: column !important;
      justify-content: center !important;
      /* align-items: center !important; */
  }
  
  .product__content-img-wrap {
      height: 150px;
  }
  
  .product__content-img-wrap img {
      /* width: 100%; */
      height: 100%;
  }

  .product__content-txt-con {
      width: 100%;
  }    
  
  .product__content-txt-top > li:nth-of-type(1) {
      margin-top: 15px;
      font-size: 20px;
      line-height: 20px;
      /* font-weight: 500; */
      /* letter-spacing: -0.5px; */
      /* color: #1AEBDB; */
      text-align: center;
  }
  
  .product__content-txt-top > li:nth-of-type(2) {
      margin-top: 10px;
      margin-left: 0;
      font-size: 20px;
      line-height: 25px;
      /* font-weight: 500; */
      /* letter-spacing: -0.5px; */
      text-align: center;
  }
      
  .product__content-txt-mid > li:nth-of-type(1) {
      margin-top: 15px;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 1px;
      /* text-align: center; */
  }
  
  .product__content-txt-mid > li:nth-of-type(2) {
      margin-top: 5px;
      font-size: 14px;
      line-height: 18px;
      word-break: keep-all;
      /* text-align: center; */
  }
  
  .product__content-txt-bot {
      margin-left: 0px;
  }
  
  .product__content-txt-bot > li {
      margin-top: 20px;
      font-size: 14px;
      line-height: 18px;
      /* font-weight: 500; */
      /* letter-spacing: -0.5px; */
      /* color: #4a4a4a; */
      /* text-align: center; */
  }    
}

/* ========================================================= */

.footer {
    padding: 40px 0;
  
    /* custom */
    background: rgb(94, 94, 94);
    color: lightgray;
  
  }
  
  .footer__con {
    padding: var(--page-padding-pc);
    margin: 0 auto;
    width: 100%;
    max-width: var(--page-width);
  
    display: flex;
    position: relative;
  }
  
  .footer__logo {
    margin-right: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: white;
  
    font-weight: bold;
  }
  
  .footer__txt-con {
   display: flex;
   align-items: center; 
  }

  .footer__txt01 {
    padding-bottom: 10px;
    font-size: 13px;
    line-height: 24px;
  }
  
  .footer__txt02 {
    font-size: 16px;
  }
  
  .footer__law-con {
    display: flex;
    position: absolute;
    right: 40px;
    bottom: 0px;
    font-size: 13px;
  }
  
  @media (max-width: 1023px) {
    .footer {
        /* padding-bottom: 40px; */
    }
    .footer__con {
        padding: var(--page-padding-tab);
        flex-direction: column;
    }
    .footer__logo {
        margin-bottom: 10px;
        justify-content: left;
        font-size: 24px;
    }
    .footer__txt01 {
        font-size: 12px;
        line-height: 20px;
    }
    
    .footer__txt02 {
        font-size: 14px;
    }    
    .footer__law-con {
        bottom: 20px;
    }
  }
  @media (max-width: 767px) {
    .footer__con {
        padding: var(--page-padding-mo);
    }
  }
  @media (max-width: 480px) {
  }

/* ========================================================= */

.section-page__header-con {
    width: 100%;
    height: 200px;
    position: relative;
  }
  
  .section-page__hedaer-txt-con {
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
    width: 100%;
    text-align: center;
  }
  
  .section-page__hedaer-txt-con > div:nth-child(1) {
    font-size: 45px;
    letter-spacing: -1px;
    font-weight: 700;
    color: #fff;
  }
  
  .section-page__hedaer-txt-con > div:nth-child(2) {
    margin-top: 30px;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -1px;
    font-weight: 400;
    word-break: keep-all;
  }
  
  
  @media (max-width: 1023px) {
    .section-page__header-con {
      height: 350px;
    }
  
    .section-page__hedaer-txt-con {
      top: 33%;
      left: 50%;
      max-width: 330px;    
    }
    
    .section-page__hedaer-txt-con > div:nth-child(1) {
      font-size: 30px;
      /* font-weight: 700; */
    }
    
    .section-page__hedaer-txt-con > div:nth-child(2) {
      margin-top: 30px;
      font-size: 14px;
      line-height: 24px;
      /* font-weight: 400; */
    }
}  

/* ========================================================= */

.license__con {
    padding: var(--page-padding-pc);
    margin: 0 auto;
    width: 100%;
    max-width: var(--page-width);
  } 
  
  .license__grid-con { 
    margin: 100px 0;
    display: flex;
    flex-wrap: wrap;
  }
  
  .license__grid-item {
    margin: 30px 10px 30px 10px;
    width: calc( (100% - 2 * 20px) / 2 );
    display: flex;
    flex-direction: column;
    /* cursor: pointer; */
    position: relative;
  }
  
  .license__source-name {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -1px;
    color: #428ef9;
    cursor: pointer;
  }

  .license__source-url {
    letter-spacing: -1px;
    opacity: 0.7;
    font-weight: 400;
    color: darkblue;
    text-decoration: underline;
  }

  .license__source-type {
    letter-spacing: -1px;
    opacity: 0.7;
  }
  
  
  .license__source-writer {
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 22px;
  }

  .license__txt-item {
    padding: 30px 0;
    border-top: 1px solid #afafaf;
  }

  .license__txt-title {
    font-size: 25px;
    font-weight: 600;
    text-align: center;
  }

  .license__txt-sub {
    margin-top: 30px;
    font-size: 14px;
    font-weight: 300;
    color: #222222;
  }
  
  
  @media (max-width: 1023px) {
    .license__con {
      padding: var(--page-padding-tab);
    } 
  
    .license__txt-sub {
      font-size: 18px;
    }
  
  }
  @media (max-width: 767px) {
    .license__con {
      padding: var(--page-padding-mo);
    }
  
    .license__grid-item {
      margin: 15px 0px 15px 0px;
      margin-bottom: 15px;
      width: calc( (100% - 2 * 10px) / 2 );
    }
  
    .license__grid-item:nth-child(2n-1) {
      margin-right: 20px;
    }
  
    .license__source-name {
      font-size: 18px;
    }
  
    
    .license__source-type {
      font-size: 15px;
    }
  
    .license__source-writer {
      font-size: 15px;
      font-weight: 400;
    }
  
   
  }
  @media (max-width: 480px) {
  }
  
  